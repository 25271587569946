var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c(
              "c-table",
              {
                ref: "table2",
                attrs: {
                  title: "설비별 점검항목",
                  columns: _vm.grid2.columns,
                  gridHeight: _vm.grid2.height,
                  editable:
                    _vm.editable && _vm.result.checkStatusCd !== "MCSC000015",
                  data: _vm.result.equipmentResultList,
                  merge: _vm.grid2.merge,
                  filtering: false,
                  columnSetting: false,
                  isExcelDown: false,
                  usePaging: false,
                  checkDisableColumn: "disable",
                  hideBottom: true,
                  noDataLabel: "설비에 대한 점검항목이 없습니다.",
                  rowKey: "checkItemSeq",
                },
              },
              [
                _vm.isMultiple
                  ? _c(
                      "template",
                      { slot: "table-chip" },
                      [
                        _c(
                          "q-btn-group",
                          { attrs: { outline: "" } },
                          [
                            _c(
                              "q-chip",
                              { attrs: { outline: "", square: "" } },
                              [
                                _c("q-avatar", {
                                  attrs: {
                                    icon: "push_pin",
                                    color: "blue-6",
                                    "text-color": "white",
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      "설비명(관리번호) : " +
                                        _vm.result.equipmentName +
                                        "(" +
                                        _vm.result.equipmentCd +
                                        ")"
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "template",
                  { slot: "table-button" },
                  [
                    _c(
                      "q-btn-group",
                      { attrs: { outline: "" } },
                      [
                        _c("c-btn", {
                          attrs: { label: "출력", icon: "print" },
                          on: { btnClicked: _vm.btnResult },
                        }),
                        _vm.isMultiple &&
                        _vm.result.equipmentResultList.length > 0 &&
                        _vm.editable &&
                        _vm.result.checkStatusCd !== "MCSC000015"
                          ? _c("c-btn", {
                              attrs: {
                                url: _vm.updateItemUrl,
                                isSubmit: _vm.isSave2,
                                param: _vm.result.equipmentResultList,
                                mappingType: "PUT",
                                label: "저장",
                                icon: "save",
                              },
                              on: {
                                beforeAction: _vm.saveItemInspection,
                                btnCallback: _vm.saveCallback,
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }